:root {
  --primary: #de6600;
  --secondary: #fea02f;
  --tertiary: #ebd9c8;
  --quarternary: #003f5a;
  --quinary: #007a7a;
}

* {
  /* outline: 1px solid red; */
}

body {
  margin: auto !important;
  font-family: 'Chirp', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: var(--tertiary) !important;
}

header {
  background: var(--quarternary);
  color: var(--primary) !important;
}

footer {
  width: 100%;
  color: var(--primary);
  font-weight: bold;
  text-decoration: none;
  font-family: 'Chirp', sans-serif;
  background: var(--quarternary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

footer a:link, a:visited {
  color: black;
  text-decoration: none;
}

footer a:hover {
  font-weight: bold;
}

footer a:active {
  font-weight: normal;
}

footer i {
  color: var(--tertiary)
}

.add-footer-cushion {
  margin-bottom: 150px;
}

.center-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

header > h2 {
  color: var(--primary) !important;
}

ul {
  height: 100%;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 10px !important;
  padding-left: 0 !important
}

li {
  list-style: none;
}

nav { 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
}

.nav-link {
  color: var(--primary) !important;
}

.active {
  color: var(--tertiary) !important;
  /* text-decoration: underline !important; */
  text-decoration-color: var(--primary) !important;
  font-weight: bold !important;

}

.about-me {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.about-image {
  width: 100%;
  text-align: center;
  margin: 25px;
  width: 25vw;
  border-radius: 50%;
  outline-style: solid;
  outline-color: black;
  outline-width: 2.5px;
}

.about-text {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.about-text p {
  min-width: 100%;
  text-align:justify;
}

.resume p {
  margin-bottom: 0;
}
.resume section{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.resume section h4, h5, h6 {
  width: 100%;
}

.resume section ul{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.resume li {
  width: 100%;
}

.project-card {
  border: 1px solid var(--primary); 
  border-radius: 25px;
  background-color: var(--quarternary);
  height: auto;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}

.project-header {
  width: 100%;
  height: 50%;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  color: var(--primary)
}

.project-img {
  border-radius: 10px;
  
}
.project-text {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--tertiary);
}
.project-links {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

svg:hover {
  fill: var(--primary);
} 

.link-icon {
  text-decoration: none;
  color: var(--tertiary);
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.link-icon:hover {
  color: var(--primary);
}

.project-tech {
  color: var(--primary);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center !important;
}
.contact-button {
  font-size: 4vw;
  color: var(--primary) !important;
}
 
.btn {
  background-color: var(--quarternary) !important;
}

.btn:hover {
  background: var(--quinary) !important;
  opacity: 85%;
}


@media screen and (min-width: 360px){
  .project-img {
    height: 60vw;
    width: 60vw;
  }
}

@media screen and (min-width: 576px){
  .contact-button {
    font-size: 3.25vw;
  }
  .project-img {
    height: 45vw;
    width: 45vw;
  }
}

@media screen and (min-width: 768px){
  .contact-button {
    font-size: 2.75vw;
  }
  .project-img {
    height: 30vw;
    width: 30vw;
  }
}

@media screen and (min-width: 992px){
  .contact-button {
    font-size: 2.25vw;
  }
  .project-img {
    height: 25vw;
    width: 25vw;
  }
}

@media screen and (min-width: 1200px){
  .contact-button {
    font-size: 2vw;
  }
  .project-img {
    height: 10vw;
    width: 10vw;
  }
}
